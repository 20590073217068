import React, { useEffect } from 'react';
import Container from '../../container';
import { Button, Card, CardBody, CardFooter, CardHeader, Chip, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Input, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronDown, faCircle, faLocationXmark,faMoneyBillsSimple, faPowerOff, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import Text from '../../text';
import avatar from "./../../../assets/avatar/avatar_1.png";
import CancelOrderModal from './../modals/cancelOrder';
import moment from 'moment';
import { ITripResourceShortProps } from '../../../props/trips';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TripService from '../../../services/trips';
import NumberService from '../../../services/general/number';
import DropdownButton, { DropdownButtonSectionProps } from '../../buttons/dropdown';
import Footer from '../../footerpage';
import * as XLSX from 'xlsx';

type ListOrderProps = {
    children?: React.ReactNode;
};

const ListOrder: React.FC<ListOrderProps> = (props) => {
    const [search, setSearch] = React.useState<string>("");
    const [searchD, setSearchD] = React.useState<string>("");
    const [activeModal, setActiveModal] = React.useState<string | undefined>();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [startDate, setStartDate] = React.useState<string>((new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })));
    const [endDate, setEndDate] = React.useState<string>((new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })));
    const [orders, setOrders] = React.useState<ITripResourceShortProps[]>([]);
    const [selectedOrder, setSelectedOrder] = React.useState<ITripResourceShortProps | undefined>();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [totalPages, setTotalPages] = React.useState(1);

    useEffect(() => {
        _onSearch(startDate, endDate);
    }, [search,searchD, currentPage, itemsPerPage]);

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

    const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
    };

    const handlePrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
    };

    const handleSetItemsPerPage = (value:any) => {
    setItemsPerPage(value);
    setCurrentPage(1)
    };

    const jsonResult = orders.map((order, index) => {
        return {
          'No': index+1,
          'Layanan': order.station_area.name,
          'Nama Customer': order.customer?.first_name+ ' ' +order.customer?.last_name ,
          'Nama Driver': order.driver?.first_name+ ' ' +order.driver?.last_name,
          'Plat Nomor': order.vehicle.vehicle_number,
          'Penjemputan':order.pickup_address,
          'Tujuan': order.drop_address ,
          'Tanggal Transaksi': order.start_time,
          'Tanggal Selesai': order.end_time,
          'Total': "Rp. "+ NumberService.getNumberWithSeparator(order.total),
          'Potongan Perjalanan': "Rp. "+ NumberService.getNumberWithSeparator(order.application_fee),
          'Surchage': "Rp. "+ NumberService.getNumberWithSeparator(order.surcharge),
          'Biaya App': "Rp. "+ NumberService.getNumberWithSeparator(order.admin_fee),
          'Pendapatan Bersih': "Rp. "+ NumberService.getNumberWithSeparator(order.net_income),
          'Status': TripService.getStatusData(order.status).text,
        };
      });

    const exportToExcel = (jsonResult: any[]) => {
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(jsonResult);
      
        // Auto-width columns
        const colWidth = jsonResult.reduce((acc, data) => {
          return Object.keys(data).reduce((acc, key) => {
            const cellLength = String(data[key]).length;
            return { ...acc, [key]: Math.max(acc[key] || 0, cellLength) };
          }, acc);
        }, {});
      
        const cols = Object.keys(colWidth).map(key => ({ wch: colWidth[key] + 2 })); // Add extra space
      
        ws['!cols'] = cols;
      
        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
        // Save the workbook to a file
        XLSX.writeFile(wb, 'Export Trip History '+startDate.split(',')[0].trim().replace(/\//g, '-') + ' Sampai ' + endDate.split(',')[0].trim().replace(/\//g, '-') +'.xlsx');
      };

    const _onSearch = async (_sd: string, _ed: string) => {
        try {
            setLoaded(false);

            const startDateTemp = new Date(_sd);
            startDateTemp.setHours(0, 0, 0, 0);

            const endDateTemp = new Date(_ed);
            endDateTemp.setHours(23, 59, 59, 0);

            const qs: string[] = [];
            qs.push(`start_time=${formatDate(startDateTemp)}`);
            qs.push(`end_time=${formatDate(endDateTemp)}`);
            if (search && search.trim() !== '') { qs.push("search=" + search); }
            if (searchD && searchD.trim() !== '') { qs.push("search_driver=" + searchD); }
            
            // Include pagination parameters
            qs.push(`page=${currentPage}`);
            qs.push(`per_page=${itemsPerPage}`);

            const _orders = (await TripService.retrieve(qs.join("&")));
            setOrders(_orders.pagination);
            setTotalPages(_orders.totalPages)
            setLoaded(true);
        } catch (e) {

        }
    }

    const renderTripCard = (trip: ITripResourceShortProps) => {
        let statusData = TripService.getStatusData(trip.status);
        return <Card>
            <CardHeader className="flex gap-3">
                <Container direction='row' alignItems='center' justifyContent='space-between' style={{ width: "100%" }}>
                    <Text size='xl' weight='bold'>Rp. {NumberService.getNumberWithSeparator(trip.total)}</Text>
                    <Container alignItems='center' gap={10} direction='row'>
                        <Container>
                            <Chip color={statusData.color}>{statusData.text}</Chip>
                        </Container>
                        {trip.status === 'picking_up' || trip.status === 'dropping_off' ? (
                        <DropdownButton icon={faChevronDown} sections={[
                            {
                            key: 'sec',
                            items: [
                                {
                                key: "cancel",
                                text: "Batalkan Pesanan",
                                variant: 'danger',
                                onPress: () => {
                                    setSelectedOrder(trip);
                                    setActiveModal('cancelOrder');
                                }
                                }
                            ]
                            }
                        ]} />
                        ) : null}
                    </Container>
                </Container>
            </CardHeader>
            <Divider />
            <CardBody>
                <Container alignItems='center' direction='row' style={{ width: '100%' }}>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                        <img src={avatar} alt="profile" style={{ height: 40, width: 40, borderRadius: 100 }} />
                        <Container>
                            <Text size='sm' weight='normal'>{[trip.driver.first_name, trip.driver.last_name].filter(Boolean).join(" ")}</Text>
                            <Text size='xs'>Driver</Text>
                        </Container>
                    </Container>
                    {trip.customer ? <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                        <img src={avatar} alt="profile" style={{ height: 40, width: 40, borderRadius: 100 }} />
                        <Container>
                            <Text size='sm' weight='normal'>{[trip.customer.first_name, trip.customer.last_name].filter(Boolean).join(" ")}</Text>
                            <Text size='xs'>Customer</Text>
                        </Container>
                    </Container> : null}
                    {!trip.customer && trip.sales ? <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                        <img src={avatar} alt="profile" style={{ height: 40, width: 40, borderRadius: 100 }} />
                        <Container>
                            <Text size='sm' weight='normal'>{trip.sales.name}</Text>
                            <Text size='xs'>Sales</Text>
                        </Container>
                    </Container> : null}
                </Container>
            </CardBody>
            <Divider />
            <CardFooter>
                <Container gap={10}>
                    <Container direction='row' alignItems='center' gap={10}>
                        <FontAwesomeIcon icon={faCircle} />
                        <Text size='sm'>{trip.pickup_address}</Text>
                    </Container>
                    <Container direction='row' alignItems='center' gap={10}>
                        <FontAwesomeIcon icon={faLocationXmark} />
                        <Text size='sm'>{trip.drop_address}</Text>
                    </Container>
                </Container>
            </CardFooter>
            <Divider />
            <CardBody>
                <Container alignItems='center' direction='row' style={{ width: '100%' }}>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                        <Text size='sm'>Tanggal & Waktu Mulai :</Text>
                        <Text size='sm'>{trip.start_time}</Text>
                    </Container>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                        <Text size='sm'>Potongan Perjalanan : </Text>
                        <Text size='sm'>Rp. {NumberService.getNumberWithSeparator(trip.application_fee)}</Text>
                    </Container>
                </Container>
                <Container alignItems='center' direction='row' style={{ width: '100%' }}>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                        <Text size='sm'>Tanggal & Waktu Selesai : </Text>
                        <Text size='sm'>{trip.end_time}</Text>
                    </Container>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                    <Text size='sm'>Surcharge : </Text>
                        <Text size='sm'>Rp. {NumberService.getNumberWithSeparator(trip.surcharge)}</Text>
                    </Container>
                </Container>
                <Container alignItems='center' direction='row' style={{ width: '100%' }}>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                        {/* <Text size='sm'>Potongan Perjalanan : </Text>
                        <Text size='sm'>Rp. {NumberService.getNumberWithSeparator(trip.application_fee)}</Text> */}
                    </Container>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                    <Text size='sm'>Biaya Pemeliharaan Aplikasi : </Text>
                        <Text size='sm'>Rp. {NumberService.getNumberWithSeparator(trip.admin_fee)}</Text>
                    </Container>
                </Container>
                <Divider />
                <Container alignItems='center' direction='row' style={{ width: '100%' }}>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                        {/* <Text size='sm'>Potongan Perjalanan : </Text>
                        <Text size='sm'>Rp. {NumberService.getNumberWithSeparator(trip.application_fee)}</Text> */}
                    </Container>
                    <Container direction='row' alignItems='center' gap={10} style={{ flex: 1 }}>
                    <Text size='sm'>Pendapatan Bersih : </Text>
                        <Text size='sm'>Rp. {NumberService.getNumberWithSeparator(trip.net_income)}</Text>
                    </Container>
                </Container>
                <Divider />
            </CardBody>
            <Divider />
        </Card>;
    }

    const handleFinish = async (row: ITripResourceShortProps) => {
        try{
            const response =  await TripService.finish(Number(row.driver_id), Number(row.id));
            _onSearch(startDate, endDate);
        }catch{

        }
    };

    const actions: DropdownButtonSectionProps[] = [
		{
			key: 'details',
			showDivider: false,
			items: [
                { key: 'cancel', text: 'Batalkan Pesanan', variant: 'danger', onPress: (trip: ITripResourceShortProps) => {
					setSelectedOrder(trip);
                    setActiveModal('cancelOrder');
				  }
				},
			]
		},
        {
			key: 'end',
			showDivider: false,
			items: [
                { key: 'finish', text: 'Selesaikan Pesanan', variant: 'success', onPress: (trip: ITripResourceShortProps) => {
					setSelectedOrder(trip);
                    handleFinish(trip);
				  }
				},
			]
		},
	];

    return (
        <Container gap={20}>
            {activeModal && activeModal === 'cancelOrder' && selectedOrder && <CancelOrderModal order={selectedOrder} onClose={function (refresh?: boolean | undefined): void {
                setActiveModal(undefined);
                setSelectedOrder(undefined);
                if (refresh) {_onSearch(startDate, endDate);}
            }} />}
            <Container direction='row' gap={10} style={{ zIndex: 1 }} alignItems='center'>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Container grow={1}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <span>Show : </span> */}
                        <select value={itemsPerPage} onChange={(e) => handleSetItemsPerPage(parseInt(e.target.value))}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={99999}>All</option>
                        </select>
                        </div>
                    </Container>
                    <Container grow={1}>
                        <DatePicker label={'Mulai Tanggal'} value={moment(startDate)} onChange={(val) => {
                            const _startDate = val ? val.startOf('day').toISOString() : startDate;
                            setStartDate(_startDate);
                            _onSearch(_startDate, endDate);
                        }} />
                    </Container>
                    <FontAwesomeIcon icon={faArrowRight} />
                    <Container grow={1}>
                        <DatePicker label={'Sampai Tanggal'} value={moment(endDate)} onChange={(val) => {
                            const _endDate = val ? val.endOf('day').toISOString() : endDate;
                            setEndDate(_endDate);
                            _onSearch(startDate, _endDate);
                        }} />
                    </Container>
                    <Container grow={1}>
                        <Input
                            placeholder="Cari Customer ..."
                            endContent={
                                <FontAwesomeIcon icon={faSearch} />
                            }
                            style={{ zIndex: 1 }}
                            value={search}
                            onValueChange={(value) => {
                                setSearch(value);
                            }}
                        />
                    </Container>
                    <Container>
                        <Input
                                placeholder="Cari Driver ..."
                                endContent={
                                    <FontAwesomeIcon icon={faSearch} />
                                }
                                style={{ zIndex: 1 }}
                                value={searchD}
                                onValueChange={(value) => {
                                    setSearchD(value);
                                }}
                            />
                    </Container>
                </LocalizationProvider>
            </Container>
            {!loaded ? <Spinner label='Memuat data pesanan ...' /> : null}
            {loaded && orders.length < 1 ? <Container>
                {moment(startDate).format("DDMMYYYY") === moment(endDate).format("DDMMYYYY") ? <Text>Belum ada pesanan pada tanggal {moment(startDate).format("DD/MM/YYYY")}</Text> : null}
                {moment(startDate).format("DDMMYYYY") !== moment(endDate).format("DDMMYYYY") ? <Text>Belum ada pesanan antara tanggal {moment(startDate).format("DD/MM/YYYY")} dan {moment(endDate).format("DD/MM/YYYY")}</Text> : null}
            </Container> : null}
            {loaded && orders.length > 0 ? <Container gap={20}>
                {/* {orders.map((order) => renderTripCard(order))} */}
                <Table aria-label="Daftar trips" isStriped>
                    <TableHeader>
                        <TableColumn>No</TableColumn>
                        <TableColumn>Status</TableColumn>
                        <TableColumn>Layanan</TableColumn>
                        <TableColumn>Nama Customer</TableColumn>
                        <TableColumn>Nama Driver</TableColumn>
                        <TableColumn>Plat Nomor</TableColumn>
                        <TableColumn>Penjemputan</TableColumn>
                        <TableColumn>Tujuan</TableColumn>
                        <TableColumn>Tanggal Transaksi</TableColumn>
                        <TableColumn>Tanggal Selesai</TableColumn>
                        <TableColumn>Total</TableColumn>
                        <TableColumn>Potongan Perjalanan</TableColumn>
                        <TableColumn>Surcharge </TableColumn>
                        <TableColumn>Biaya App</TableColumn>
                        <TableColumn>Tabungan</TableColumn>
                        <TableColumn>Pendapatan Bersih</TableColumn>
                        <TableColumn>Action</TableColumn>
                    </TableHeader>
                    <TableBody isLoading={!loaded} loadingContent={<Spinner />} emptyContent={<Text>Belum ada data</Text>}>
                        {orders && orders.map((order, index) => {
                        const calculatedIndex = (currentPage - 1) * itemsPerPage + index + 1;
                        let statusData = TripService.getStatusData(order.status);
                        return <TableRow key={order.driver?.id??''}>
                            <TableCell aria-label='no'>
                                <Text size='base' weight='medium'>{calculatedIndex}</Text>
                            </TableCell>
                            <TableCell aria-label='status'>
                                <Chip color={statusData.color}>{statusData.text}</Chip>
                            </TableCell>
                            <TableCell aria-label='layanan'>
                                <Text size='base' weight='medium'>{order.station_area?.name}</Text>
                            </TableCell>
                            <TableCell aria-label='nama_customer'>
                                <Text size='base' weight='medium'>{order.customer?.first_name} {order.customer?.last_name || ""}</Text>
                            </TableCell>
                            <TableCell aria-label='nama_driver'>
                                <Text size='base' weight='medium'>{order.driver?.first_name} {order.driver?.last_name || ""}</Text>
                            </TableCell>
                            <TableCell aria-label='plat'>
                                <Text size='base' weight='medium'>{order.vehicle.vehicle_number}</Text>
                            </TableCell>
                            <TableCell aria-label='pickup'>
                                <Text size='base' weight='medium'>{order.pickup_address}</Text>
                            </TableCell>
                            <TableCell aria-label='dropoff'>
                                <Text size='base' weight='medium'>{order.drop_address}</Text>
                            </TableCell>
                            <TableCell aria-label='start'>
                                <Text size='base' weight='medium'>{order.start_time}</Text>
                            </TableCell>
                            <TableCell aria-label='finish'>
                                <Text size='base' weight='medium'>{order.end_time}</Text>
                            </TableCell>
                            <TableCell aria-label='total'>
                                <Text size='base' weight='medium'> Rp. {NumberService.getNumberWithSeparator(order.total)}</Text>
                            </TableCell>
                            <TableCell aria-label='fee'>
                                <Text size='base' weight='medium'>Rp. {NumberService.getNumberWithSeparator(order.application_fee)}</Text>
                            </TableCell>
                            <TableCell aria-label='surcharge'>
                                <Text size='base' weight='medium'>Rp. {NumberService.getNumberWithSeparator(order.surcharge)}</Text>
                            </TableCell>
                            <TableCell aria-label='admin'>
                                <Text size='base' weight='medium'>Rp. {NumberService.getNumberWithSeparator(order.admin_fee)}</Text>
                            </TableCell>
                            <TableCell aria-label='admin'>
                                <Text size='base' weight='medium'>Rp. {NumberService.getNumberWithSeparator(order.tabungan)}</Text>
                            </TableCell>
                            <TableCell aria-label='final'>
                                <Text size='base' weight='medium'>Rp. {NumberService.getNumberWithSeparator(order.net_income-order.tabungan)}</Text>
                            </TableCell>
                            <TableCell aria-label='action'>
                                {order.status === 'picking_up' || order.status === 'dropping_off' ? (
                                    <DropdownButton icon={faChevronDown} sections={actions} backdrop='blur' data={order} />
                                ): null}
                            </TableCell>
                        </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Container> : null}
            <Container direction="row" justifyContent="flex-end">
            <Footer
                currentPage={currentPage}
                totalPages={totalPages}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                buttons={[
                {
                    key: 'back',
                    color: 'primary',
                    width: '30%',
                    children: 'Previous',
                    isDisabled: currentPage === 1,
                    onClick: handlePrevPage,
                    isLoading: !loaded
                },
                {
                    key: 'number',
                    width: '30%',
                    children: ' Page '+currentPage+' of ' + totalPages,
                    isDisabled: currentPage === totalPages,
                },
                {
                    key: 'next',
                    color: 'success',
                    width: '30%',
                    children: 'Next',
                    isDisabled: currentPage === totalPages,
                    onClick: handleNextPage,
                    isLoading: !loaded
                },
                {
                    key: 'download',
                    color: 'danger',
                    width: '30%',
                    children: 'Excel',
                    onClick: () => exportToExcel(jsonResult),
                },
                ]}
            />
        </Container>
        </Container>
    )
};

export default ListOrder;