import { ICustomerResourceShort } from '../../props/customers';
import { ICustomerResourceShortAbsensi } from '../../props/customers/absensi';
import { ICustomerWalletHistoryResourceShortProps } from '../../props/customers/walletHistory';
import { ICustomerWalletHistoryNewResourceShortProps } from '../../props/customers/walletHistoryNew';
import Request from './../general/request';

const CustomerService = {
  retrieve: async (qs?: string) => {
    try {
      const response = await Request.get<ICustomerResourceShort[]>(`/api/admin/customers?${qs || ""}`);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  wallet: {
    history: {
      retrieveNew: async (qs?: string) => {
        try {
          const response = await Request.get<ICustomerWalletHistoryNewResourceShortProps>(`/api/admin/drivers/wallet_histories_public?${qs || ""}`);
          return response;
        } catch (error) {
          throw error
        }
      }
    }
  },
  retrieve_absensi: async (qs?: string) => {
    try {
      const response = await Request.get<ICustomerResourceShortAbsensi[]>(`/api/admin/trips/requests/absen_lists?${qs||""}`);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  setAsSales: async (customerId: number, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/customers/${customerId}/is_sales`, data);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  setConfirmAbsen: async (customerId: number, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/trips/requests/absen_confirmation/${customerId}`, data);
      return response.result;
    } catch (error) {
      throw error
    }
  },
  read: async (customerId: string) => {
    try {
      const response = await Request.get<ICustomerResourceShort>(`/api/admin/customers/${customerId}`);
      return response;
    } catch (error) {
      throw error
    }
  },
  update: async (customerId: string, data: FormData) => {
    try {
      const response = await Request.put<ICustomerResourceShort>(`/api/admin/customers/${customerId}`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  updatePassword: async (customerId: string, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/customers/${customerId}/update_password`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  updateQuotaSearch: async (customerId: string, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/customers/${customerId}/update_quota`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  updateStatus: async (customerId: string, data: FormData) => {
    try {
      const response = await Request.post<ICustomerResourceShort>(`/api/admin/customers/${customerId}/update_status`, data);
      return response;
    } catch (error) {
      throw error
    }
  },
  history: {
      analytics: {
        getTotalAmount: (histories: ICustomerWalletHistoryResourceShortProps[]) => {
          let topup = 0;
          let cash = 0;
          let ewallet = 0;
          let qrcode = 0;
          let absensi = 0;

          histories.forEach((history) => {
            topup += Number(history.amount);
            if (history.payment_method === 'ewallet') {
              ewallet += Number(history.amount);
            } else if (history.payment_method === 'qrcode') {
              qrcode += Number(history.amount);
            } else {
              cash += Number(history.amount);
            }
            if (history.history_type === 'absensi') {
              absensi += Number(history.amount);
            }
          });

          return { topup, ewallet, cash, qrcode, absensi };
        }
      },
      retrieve: async (qs?: string) => {
        try {
          const response = await Request.get<ICustomerWalletHistoryResourceShortProps[]>(`/api/admin/drivers/wallets/histories?${qs || ""}`);
          return response;
        } catch (error) {
          throw error
        }
      },
      absensi: async (qs?: string) => {
        try {
          const response = await Request.get<ICustomerWalletHistoryResourceShortProps[]>(`/api/admin/drivers/wallets/absensi?${qs || ""}`);
          return response;
        } catch (error) {
          throw error
        }
      },
      list: async (qs?: string) => {
        try {
          const response = await Request.get<ICustomerWalletHistoryResourceShortProps[]>(`/api/admin/list/wallet/histories_sales?${qs || ""}`);
          return response;
        } catch (error) {
          throw error
        }
      },
    }
}

export default CustomerService;